@charset "utf-8";

%easeinout {
  transition: all .1s ease-in-out;
}

%easeinback {
  transition: all .450s cubic-bezier(.680, -.550, .265, 1.550);
}

%easeoutback {
  transition: all .500s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

%shadow {
  box-shadow: 0 .1rem $dark
}

%deboss {
  background: $dark;
  box-shadow: inset 0 .05rem darken($dark,10%)
}

%flip {
  scale: -1;
  filter: "FlipH";
  -ms-filter: "FlipH";
}

* {
  box-sizing: border-box
}

a,
a * {
  cursor: pointer;
}

::-moz-selection {
  background: transparent;
}

::selection {
  background: transparent;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body,
html {
  -ms-overflow-style: none!important;
  background: $bg;
}

button,
html,
input,
select,
textarea {
  font-family: $font;
  color: $hilite;
  font-weight: normal;
}

body {
  text-align: center;
  background: $bg;
  line-height: $line-height;
  margin: 0;
  font-size: $font-size;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

.page {
  padding-top: $gutter;
}

.bottom {
  display: none;
}

.content {
  padding: $gutter;
  overflow: hidden;
  max-width: 654px;
  margin: 0 auto;
}

header {
  padding-top: 3rem+$gutter;
  background:url("../img/logo.svg") no-repeat 50% 0;
  background-size: auto 3rem;
}

.arrow {
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
  pointer-events: none;
  cursor: pointer;

  path {
    fill: $button-text;
  };
}

p {
  margin: 0 0 1rem;

  &:last-child {
    margin-bottom: 0;
  };
}

h2 {
  margin: 1rem 0;
  display: block;
  font-size: $l;
}

a {
  text-decoration: none;
}

small {
  font-size: $s;
}

li,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

.loading {
  background: $bg;
  text-align: center;
}

.spinner {
  @extend %ir;
  background: $hilite;
  margin: 0 auto;
  @include circle(1.5rem);
  @include animation(spin .75s infinite linear);

}
@keyframes spin {
  0% {
    transform: rotateY(0);
    background: $hilite;
  }

  25% {
    transform: rotateY(90deg);
    background: $hilite;
  }

  26% {
    transform: rotateY(91deg);
    background: $dark;
  }

  50% {
    transform: rotateY(180deg);
    background: $dark;
  }

  75% {
    transform: rotateY(270deg);
    background: $dark;
  }

  76% {
    transform: rotateY(271deg);
    background: $hilite;
  }

  100% {
    transform: rotateY(360deg);
    background: $hilite;
  }
}

.button {
  margin-bottom: 0.5rem;
  @extend %rounded;
  overflow: hidden;
  font-weight: bold;
  @extend %shadow;
}

.button-inner {
  display: block;
  position: relative; /* <-- this might break things in android */
  text-align: center;
  padding: 0.5rem 0.66rem;
  color: $button-text;
  line-height: 1.5rem;
  background-color: $button-bg;
  @extend %rounded;
  text-overflow: ellipsis;
  width: 100%;
  border: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.button-inner * {
  cursor: pointer;
}

.button-inner .date {
  float: left;
  z-index: 999;
  position: relative;
}

.button-inner .result {
  float: right;
  z-index: 999;
  position: relative;
}

.button-inner.menu-block-played {
  @extend %deboss;
  color: $hilite;
}

.button.st-selected .answer-inner {
  @extend %deboss;
  color: $hilite;
}

.button.st-wrong .button-inner,
.button.st-wrong.st-selected .button-inner {
  background-color: $answer-wrong-bg!important;
  color: $answer-wrong-text;
}

.button.st-right .button-inner,
.button.st-right.st-selected .button-inner {
  background-color: $answer-right-bg!important;
  color: $answer-right-text;
}

.button:last-child {
  margin-bottom: 0;
}

.button-newgame .arrow {
  margin-right: -1.5rem;
  float: left;
  @extend %flip;
}

.button-submitanswers .arrow {
  margin-left: -1.5rem;
  float: right;

  path {
    fill: $hilite;
  };
}

.button-disabled .arrow {
  display: none;
}


.button-share .button-inner {
  background: $facebook;
  color: $hilite;
}

.button-share-twitter .button-inner {
  background: $twitter;
  color: $hilite;
}

.button-share-whatsapp .button-inner {
  background: $whatsapp;
  color: $hilite;
}

.button-inner.menu-block {
  overflow: hidden;
  font-weight: bold;
}

.button-inner.menu-block .arrow {
  float: right;
}
// home
.quiz-menu {
  display: block;
}

.install {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0.75*$gutter 0.75*$gutter 1.5*$gutter;
  background: rgba(0,0,0,.9);
  color: $hilite;
  font-size: $s;
}

.install-arrow {
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: 0.75*$gutter;
  margin-left: -.3rem;
  border-left: 0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top: 0.3rem solid $hilite;
}
// game
.question-no {
  @include circle(2rem);
  display: inline-block;
  @extend %deboss;
  line-height: 2rem;
  text-align: center;
  color: $bg;
  font-weight: bold;
  margin-bottom: 1rem;
}

.question-block {
  padding: $gutter*1.5 $gutter;
  box-shadow: inset 0 1px tint(saturate($bg,30%),20%);
  border-top: 1px solid darken($bg,10%);
  font-weight: bold;
}

.question-block,
.submit-block {
  margin-left: -$gutter;
  margin-right: -$gutter;
  overflow: hidden;
}

.question-block.active {
  background: $hilite;
}

.submit-block {
  margin-bottom: -$gutter;
  padding: $gutter;
  background: $dark;
  border-top: 1px solid darken($bg,20%);
}

.submit-block .button {
  margin-bottom: 0;
}

.submit-block .button-inner {
  background: $bg;
  color: $hilite;
}

.submit-block .button-disabled .button-inner {
  color: $dark;
}

.answers {
  width: 100%;
  padding-top: 1.5*$gutter;
}
// end
.percent-bar-container {
  clear: both;
  text-align: center;
}

.percent-bar-container p {
  display: block;
  height: 2.5rem;
  font-size: $s;
  line-height: 2.5rem;
  margin: 1rem 0 0;
}

.percent-bar {
  width: 100%;
  @extend %deboss;
  height: 2.5rem;
  @extend %rounded;
  overflow: hidden;
  margin-bottom: 1rem;
}

.percent-bar div {
  float: left;
  white-space: nowrap;
  color: $hilite;
  line-height: 2.5rem;
  font-weight: bold;
}

.percent-right {
  background: $green;
  text-align: left;
  text-indent: 1rem;
  @extend %easeoutback;

  &.percent-zero {
    width: 0!important;
  }
}

.percent-green {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background: $green;
}

.percent-wrong {
  @extend %deboss;
  text-align: right;
  direction: rtl;
  text-indent: 1rem;
}

.percent-rest {
  text-align: right;
  @extend %deboss;
}

.end-actions,
.end-stats {
  padding-bottom: 1.5rem;
}

.percentile-circle {
  @include circle(4rem);
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 1rem .25rem;
  @extend %deboss;
  vertical-align: middle;

}

.percentile-text {
  line-height:4rem;
  vertical-align: middle;
}

.percentile-circle-fill {
  background: $green;
  width: 100%;
  position: absolute;
  bottom: 0;
  @extend %easeoutback;

  &.percent-zero {
    height: 0!important;
  }
}

.percentile-circle-text {
  position: absolute;
  width: 100%;
  line-height: 4rem;
  font-size:1.5rem;
  color: $hilite;
  text-align: center;
  font-weight: bold;
  z-index: 9999;
}

.percentile-circle-fill-red {
  background: $red;
}

footer {
  clear: both;
  text-align: center;
  font-size: 0.66rem;
  text-transform: uppercase;
  font-weight: bold;
  color: $dark;
  padding: 2rem 0;
}

.footer-links {
  margin: 3*$gutter $gutter $gutter;
  a {
    display:inline-block;
    margin:.5rem;
    color: $hilite;
    font-weight: normal;
  }
}

.footer-links button {
  border: inherit;
  background-color: inherit;
  text-transform: inherit;
  font-size: inherit;
  cursor: pointer;
}

.footer-logo {
  display: block;
  @extend %ir;
  width: 100%;
  margin-top: 1.5rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.footer-logo-sanomagames {
  background-image:url("../img/sanomagames.svg");
}

.footer-logo-iltasanomat {
  background-image:url("../img/iltasanomat.svg");
}

// Competition form
.form {
  margin-bottom: $gutter;
  padding: $gutter;
  @extend %deboss;

  p {
    font-size: $s;
  }
  .form-link {
    color: #fff;
    text-decoration: underline;
    img {
      margin-bottom: calc($gutter / 2);
    }
  }

  .form-item {
    margin-bottom: $gutter;
  }
  .input-inner {
    display: block;
    background: $button-bg;
    border: 1px solid $dark;
    color: $button-text;
    width: 100%;
    padding: 0.25 * $gutter;
    font-size: $m;
  }

  .input-checkbox {
    width: 100%;
    font-size: 80%;
    display: block;
  }
}

.rules {
  p {
    font-size: $s;
  }
}

// banners
.banner {
  .banner-slot {
    display: inline-block;
    margin: 0 auto;
  }
}

// standalone mode
@media (min-device-width:320px) and (max-device-width:768px) {
  html.standalone {
    .loading,
    body {
      border-radius: 5px;
    }

    .page {
      padding-top: $gutter;
    }
  }

  .form img {
    max-height: 200px;
  }
}
@media (min-width:655px) {
  .submit-block {
    border-radius: $border-radius $border-radius 0 0;
  }
}
// bigger than iPhone
@media (min-width:569px) {
  html {
    font-size: 150%;
  }

  .install {
    display: none!important;
  }
}

.midreport-results {margin-top:2rem;}

/* separate ad */
html.ad {
  font-size: 100%;
  background: #fff;
  height: 120px;
}

html.ad body {
  background: #fff;
}

.lift-wrap {
  width: 100%;
  text-align: center;
  line-height: 1.3;

  .lift {
    width: 100%;
    display:flex;
    background: $bg;
    height:120px;
    overflow: hidden;
    align-items: center;
  }

  .lift-inner {
    width: 100%;
    background: $bg;
    padding: 1rem;
  }

  p {
    color: #fff;
    font-weight: bold;
    margin: 0 0 0.5rem !important;
    padding: 0 !important;
  }

  .button {
    width: 100%;
  }

  .button-inner {
    padding: 0.5rem;
    line-height:1.1;

  }
}

.share-title {}

.share {
  line-height: 2.25rem;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  margin-bottom:2rem;
}

.button-share {
  background: #fff;
  border-radius: 100%;
  //vertical-align: top;
  height: 3.5rem;
  margin:0  0.5rem;
  overflow: hidden;
  display: block;
  padding: 0;
  //vertical-align: middle;
  width: 3.5rem;
  height: 3.5rem;
  border:.15rem solid #fff;
  cursor: pointer;
  &:hover {transform: scale(1.1)}

  svg {
    width: 100%;
  }

  &:hover {
    background: #fff;
  }
}


.button-share-facebook {
  svg {fill: $facebook;}
}

.button-share-twitter {
  svg {fill: $twitter;}
}

.button-share-whatsapp {
  svg {fill: $whatsapp;}
}



.break-notice {
  background:#fff;padding:1rem;font-size:75%;color:$black;font-weight:400;margin-bottom:1rem;
  a {text-decoration: underline;color:$bg;font-weight: bold;}
}

.st__hidden {display: none;}
.st__visible {display:block!important;}

#items.loop .question-block {opacity:0;transition:all 300ms ease-out;display:none;box-shadow:none;border:0;transform:scale(.8);padding-top:0;}
#items.showall, #items.showall .question-block {display:block!important}
.opacity-1 {opacity: 1!important;transform: translateY(1rem)}

.gitlab-testi { width: 100% }