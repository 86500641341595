// Placeholders (no variable, use with @extend) ----- //
// =borderbox prefixes
%border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
// =userselect:none prefixes
%user-select-none {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
// image replacement
%ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
// border-radius (compass, need a variable defined)
%rounded {
  border-radius: $border-radius
}
// simple transition (compass)
%animate {
  transition: all .05s ease-in-out;
}
// inline-block (compass)
%inline-block {
  display: inline-block
}

.hidden {
  display: none;
}

.no-animate {
  -webkit-transition: none!important;
  -moz-transition: none!important;
  -o-transition: none!important;
  transition: none!important;
}
// Mixins  ------------------------------------- //
// =selection
@mixin selection($color) {
  ::-moz-selection {
    background: $color;
    text-shadow: none;
  }

  ::selection {
    background: $color;
    text-shadow: none;
  }
}
@mixin svg($name) {
  .svg & {
    background-image: url('/img/#{$name}.svg');
  }

  .no-svg & {
    background-image: url('/img/#{$name}.png');
  }
}

// =circle
@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: $size;
}
// =fade single line text to a color. ellipsis fallback
@mixin text-fade($fade-color) {
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &:after {
    content: " ";
    width: 15px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    @include background-image(linear-gradient(left,rgba($fade-color,0),rgba($fade-color,1)));
  }

  .no-cssgradients.no-rgba {
    text-overflow: ellipsis;
  }
}
// =input/textarea placeholder color prefixes
@mixin placeholder-color($color) {
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }

  ::-webkit-textarea-placeholder {
    /* WebKit browsers */
    color: $color;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }

  :-ms-textarea-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}
// =keyframe prefixes
@mixin keyframes($name) {
   @-webkit-keyframes #{$name}{
    @content;
  }
  @-moz-keyframes #{$name} {
     @content;
  }
  @-ms-keyframes #{$name} {
     @content;
  }
  @keyframes #{$name} {
     @content;
  }
}
// =animation prefixes
@mixin animation($name) {
  -webkit-animation: $name;
  -moz-animation: $name;
  -ms-animation: $name;
  -o-animation: $name;
  animation: $name;
}
// Animation mixins ------------------------------------- //
@mixin animation-fill-mode($fillmode) {
  -webkit-animation-fill-mode: $fillmode;
  -moz-animation-fill-mode: $fillmode;
  -ms-animation-fill-mode: $fillmode;
  -o-animation-fill-mode: $fillmode;
  animation-fill-mode: $fillmode;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -ms-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-delay($delay) {
  -webkit-animation-duration: $delay;
  -moz-animation-duration: $delay;
  -ms-animation-duration: $delay;
  -o-animation-duration: $delay;
  animation-duration: $delay;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
  -moz-animation-timing-function: $timing-function;
  -ms-animation-timingfunction: $timing-function;
  -o-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
  -moz-animation-iteration-count: $iteration-count;
  -ms-animation-iteration-count: $iteration-count;
  -o-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -ms-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -ms-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
}
