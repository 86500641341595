// Settings ------------------------------------- //
$gutter: 1rem;

/* type */
$font: 'Source Sans Pro',sans-serif;
$font-size: 1.2rem;
$line-height: 1.4;

/* colors */
$red: #ff5454;
$green: #b4d257;
$facebook: #3B5998;
$twitter: #4DACAC;
$whatsapp: #3F8A7E;
$black: #333;
$bg: #a92320;
$dark: darken($bg,15%);
$hilite: #fff;
$button-bg: $hilite;
$button-text: $black;
$answer-bg: $button-bg;
$answer-text: $button-text;
$answer-right-bg: $green;
$answer-right-text: $hilite;
$answer-wrong-bg: $red;
$answer-wrong-text: $hilite;
$answer-selected-bg: $dark;
$answer-selected-text: $hilite;
$border-radius: 0.3rem;
$s: 0.8rem;
$m: $font-size;
$l: 1.25rem;
